import { StrictMode } from "react";
import ReactDOM from "react-dom";

import App from "./App";

import ReactGA from "react-ga";
const trackingID = "UA-59586848-1";
new Promise((resolve) => {
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    a.addEventListener("load", resolve);
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  window.ga("create", trackingID, "auto");
  window.ga("send", "pageview");
}).then(() => {
  ReactGA.initialize(trackingID, {
    standardImplementation: true
  });
  ReactGA.pageview("/index.html");
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
);
